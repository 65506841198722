import React from 'react';
import Spacer from './spacer'

function NotFoundPage() {
    return (
        <div>
            <Spacer
                height="50px"
                isDarkMode={false}
                setIsDarkMode={false}
                showHomeButton
            />
            <h1>404 Not Found</h1>
            <p>The page you are looking for does not exist.</p>
        </div>
    );
}

export default NotFoundPage;
