import nalaCowboy from "../assets/nalacowboycropped.png";
import React from 'react'; 

function NalaCowboy() {
  return (
    <div>
      <br></br>
      <br></br>
      <br></br>
      <img src={nalaCowboy} alt="Nala Cowboy" className="nala-image" />
      <br></br>
      <br></br>
      <br></br>
    </div>
  );
}

export default NalaCowboy;
